import React from 'react'
// import { Link } from 'gatsby'
import Layout from '../../components/layout'
import Seo from '../../components/seo'
import PageHeader from '../../components/pageHeader'
const Avtalsvillkor = () => (
  <Layout>
    <Seo title='Avtalsvillkor' />
    <PageHeader text='Avtalsvillkor' />
    <div className='container'>
      <div className='row justify-content-around'>
        <div className='col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8'>

          <h1>Våra Avtalsvillkor</h1>
          <p>
            <i> Danderyds Trafikskola drivs som ett aktiebolag sedan 1974. Vi är godkända för F-skatt
              <br />
              <br />
              Vi erbjuder körkortsutbildning, Riskutbildningar och Introduktionsutbildningar.
              Vi tillämpar oss av STR:s policy gällande avbokningsregler/reklamations - och återbetalningspraxis (www.str.se).
            </i>
          </p>
          <h5>Avtalsvillkor – DanderydsTrafikskola AB</h5>
          <h6 className='mb-0'>
            §1 Acceptera avtalsvillkor&nbsp;
          </h6>
          <p>
            Genom att skriva in dig på DanderydsTrafikskola AB blir du kund och användare av våra tjänster och accepterar därmed dessa avtalsvillkor.
          </p>
          <h6 className='mb-0'>§2 Minderårig&nbsp;</h6>
          <p>
            Om du är under 18 år måste du enligt lag ha dina föräldrars eller annan förmyndares samtycke för att gå med på våra avtalsvillkor. Innan inskrivning kan ske måste du kunna uppvisa för trafikskolan ett skriftligt intyg undertecknat från dina vårdnadshavare/förmyndare, om samtycket inte kan skaffas på annat godtagbart sätt.
          </p>

          <h6 className='mb-0'>§3 Personligt konto via Elevcentralen&nbsp;</h6>
          <p>
            Ditt konto är personligt. Inloggning och lösenord skall användas av dig, du har själv ansvar för skydda detta.<br />
            DanderydsTrafikskola AB är inte ansvariga för eventuell skada som uppstått om någon annan utomstående loggar in och använder ditt konto.
            Som kund har du tillgång till Elevcentralen och dess tjänster under hela din utbildning.<br />
            Danderyds Trafikskola AB förbehåller sig rätten att stänga av kontot vid missbruk av tjänsten.
          </p>

          <h6 className='mb-0'>§4 Dina personuppgifter&nbsp;</h6>
          <p>
            Dessa är de du lämnat till oss och valt att uppge.
            Dina personuppgifter skyddas av PUL. Vi kommer aldrig föra dina personuppgifter vidare till tredje part.
            All data hanteras på en säker server som driftas av STR Service AB i Landskrona.<br />Var noga med att alltid ha informera oss om du byter adress, telefonnummer och e-post för vår kontakt med er<strong>.</strong>
          </p>

          <h6 className='mb-0'>§5 Priser och Betalning</h6>
          <p>Aktuella priser hittar du på vår webbplats. Priserna kan komma att ändras under utbildningens gång.<br />
            För att kunna tillgodoräkna sig rabatterna i våra paketerbjudanden&nbsp;krävs förskottsbetalning.
            Priserna i ett Paketerbjudande&nbsp;gäller i två (2) år från betalningsdatum och berörs då ej av eventuella prisändringar.<br />
            Vi tar emot betalningar via BankGiro, bankkort och kontant.
          </p>

          <h6 className='mb-0'>§6 Fakturering</h6>
          <p>
            Danderyds Trafikskola tillämpar fakturabetalning för de produkter och tjänster som kunden köper och som inte betalas vid kurs eller köptillfället.
            Fakturan skickas till den adress, som kunden uppgett vid inskrivningen.<br />
            Det är upp till kunden, att informera om förändrade adressuppgifter. Avgifter som tillkommer pga. felaktiga adressuppgifter debiteras kunden.
            Betalningsvillkor är 20 dagar.
          </p>

          <h6 className='mb-0'>§7 Avbokningsregler&nbsp;</h6>
          <p>
            Vid avbokning, annan avbeställning m.m. tillämpas STR:s praxis angående ersättning och återbetalning vid avbeställning m.m. se&nbsp;
            <a href='http://www.str.se/'>www.str.se</a>&nbsp;(<a href='https://www.str.se/globalassets/om-oss/dokument/str_praxis_ersattning_aterbetalning.pdf'>STR:s praxis</a>).
            Därvid gäller att trafikskolan i enlighet med det som sägs i p.
            3 och 4 (i STR:s praxis) har rätt att ta ut en administrationskostnad om tio procent av kursavgiften.
          </p>

          <p><strong>Avbokning av körlektion skall ske senast kl. 14.00 vardagen innan lektionsdatum! Avbokning får inte ske via mail.</strong></p>

          <h6 className='mb-0'>§8 Alkolåsanvändning&nbsp;</h6>
          <p>Danderyds Trafikskola AB´s samtliga fordon är utrustade med alkolås. Kunden måste vid varje körlektion göra ett godkänt utandningsprov. Vid ej godkänt utandningsprov genomförs ej körlektionen, men debiteras kunden.</p>

          <h6 className='mb-0'>§9 Upphovsrätt och nyttjanderätt&nbsp;</h6>
          <p>Allt material är skyddat av lagen om upphovsrätt.</p>

          <h6 className='mb-0'>§10 Friskrivning från ansvar&nbsp;</h6>

          <p>Danderyds Trafikskola AB tar inte ansvar för avgifter som kan tillkomma vid påminnelse eller inkassokrav om kunden ej uppgett rätt adress eller uppdaterat oss med rätt uppgifter.</p>

          <h6 className='mb-0'>§11 Ändringar av dessa Avtalsvillkor&nbsp;</h6>
          <p>Avtalsvillkoren kan komma att ändras under körkortsutbildningen. Då kommer detta att aviseras på elevcentralen. Ett godkännande av avtalsvillkoren via elevcentralen, anses att kunden accepterat avtalet.</p>
          <h6 className='mb-0'>§12 Vid eventuell tvist&nbsp;</h6>
          <p>Danderyds Trafikskola AB kommer göra allt för att du som kund skall bli nöjd. Skulle vi misslyckas med detta skall du som kund i första hand vända sig till skolchefen&nbsp;på DanderydsTrafikskola AB.<br />I annat fall skall du som kund vända dig till STR:s Reklamationsnämnd (<em>www.str.se</em>)</p>

          <p><em>Avtalsvillkor 2022-03-10 ver. 4.0</em></p>

        </div>

      </div>
    </div>
  </Layout>
)

export default Avtalsvillkor
